
import Vue from 'vue';

export default Vue.extend({
  name: 'FormItem',
  props: {
    label: {
      type: String,
      default: '',
    },
    labelWidth: {
      type: [Number, String],
      default: '120px',
    },
    tip: {
      type: String,
      default: '',
    },
    required: Boolean,
  },
  computed: {
    labelStyle() {
      return {
        width: typeof this.labelWidth === 'number' ? `${this.labelWidth}px` : this.labelWidth,
      };
    },
  },
});
