import Vue from 'vue';
import Vuex from 'vuex';
import ZPanel from '@/components/base/panel/';
import ZTable from '@/components/base/table/';
import ZSwitch from '@/components/base/z-switch/';
import ZSelect from '@/components/base/z-select/';
import Btn from '@/components/base/btn/';
import Pagenation from '@/components/base/pagenation/';
import Modal from '@/components/base/modal/';
import FormItem from '@/components/base/form-item/';
const { mapGetters, mapActions } = Vuex;

import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import { ResourceTypeEnum, ResourceTypeDict, MethodTypeDict } from './constans';
import { objToArray } from '@/utils';

export default Vue.extend({
  name: 'AdminMenu',
  components: {
    ZPanel,
    ZTable,
    ZSwitch,
    ZSelect,
    Pagenation,
    Modal,
    FormItem,
    Treeselect,
    Btn,
  },
  data() {
    return {
      page: 1,
      limit: 100,
      pageTotal: 0,
      tableData: [],
      isLoading: false,
      isAddLoading: false,
      isEditLoading: false,
      isDeleteLoading: false,
      currentRow: {},
      isShowDeleteModal: false,
      isShowFormModal: false,
      editMode: 'add',
      formData: { type: 2 },
      types: [
        {
          value: 2,
          label: '填品',
        },
        {
          value: 1,
          label: '分类',
        },
        {
          value: 3,
          label: '图片3',
        },
        {
          value: 4,
          label: '图片4',
        },
        {
          value: 5,
          label: '图片5',
        },
        {
          value: 6,
          label: '图片6',
        },
        {
          value: 7,
          label: '图片7',
        },
      ],
      brandIds: [
        {
          value: 252031,
          label: '雅思兰黛',
        },
        {
          value: 252029,
          label: '芭比波朗',
        },
        {
          value: 252025,
          label: '倩碧',
        },
        {
          value: 252331,
          label: '蒂佳婷',
        },
        {
          value: 252171,
          label: '汤姆福特',
        },
        {
          value: 252050,
          label: '悦木之源',
        },
        {
          value: 252169,
          label: '祖玛珑',
        },
        {
          value: 252189,
          label: '魅可',
        },
      ],
      brandId: 252025,
    };
  },
  computed: {
    ...mapGetters('common', {
      userInfo: 'getUserInfo',
    }),
    menuTree() {
      return this.tableData;
    },
    ResourceTypeEnum() {
      return ResourceTypeEnum;
    },
    RsourceTypeArr() {
      return objToArray(ResourceTypeDict, { initVal: false });
    },
    MethodTypeArr() {
      return objToArray(MethodTypeDict, { valueType: 'string' });
    },
  },
  mounted() {
    this.requestTableList();
  },
  methods: {
    ...mapActions({
      toggleSignInModal: 'common/toggleSignInModal',
    }),

    /**
     * @desc 刷新
     */
    refresh() {
      this.requestTableList();
    },

    /**
     * @desc 请求 获取资源列表
     */
    requestTableList() {
      this.isLoading = true;
      const params = {
        page: this.page,
        pageSize: this.limit,
        brandId: this.brandId,
      };
      this.$myApi.categoryMenu
        .index(params)
        .then((res: any) => {
          this.tableData = res?.data.rows || [];

          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    /**
     * @desc 同步数据
     */
    synchronize() {
      const params = {
        page: this.page,
        pageSize: this.limit,
        brandId: this.brandId,
      };
      this.$myApi.categoryMenu
        .synchronizeMockData(params)
        .then((res: any) => {
          console.log('🚀 ~ file: index.ts ~ line 102 ~ .then ~ this.tableData', res);
        })
        .catch(err => {
          console.log('🚀 ~ file: index.ts ~ line 169 ~ synchronize ~ err', err);
        });
    },

    /**
     * @desc 请求 新增
     */
    requestAdd() {
      if (this.formData.type == 2 && this.formData.products1) {
        this.formData.products = this.formData.products1.split(',');
      } else {
        this.formData.products = [];
      }
      const params = {
        ...this.formData,
        brand_id: this.brandId,
        parent_id: this.formData.parent_id || 0,
      };

      this.isAddLoading = true;
      this.$myApi.categoryMenu
        .create(params)
        .then(() => {
          this.isAddLoading = false;
          this.$toast.success('添加成功！');
          this.handleHideFormModal();
          this.requestTableList();
        })
        .catch(err => {
          this.isAddLoading = false;
          this.$toast.error(err?.data?.msg || err?.msg || '添加失败');
        });
    },

    /**
     * @desc 请求 修改
     */
    requestEdit() {
      console.log(this.formData.products1);
      if (this.formData.type == 2 && this.formData.products1) {
        this.formData.products = this.formData.products1.split(',');
      } else {
        this.formData.products = [];
      }
      console.log(this.formData.products1);

      const params = {
        ...this.formData,
        brand_id: this.brandId,
        id: this.currentRow.id,
      };
      this.isEditLoading = true;
      this.$myApi.categoryMenu
        .update(params)
        .then(() => {
          this.isEditLoading = false;
          this.$toast.success('修改成功！');
          this.handleHideFormModal();
          this.requestTableList();
        })
        .catch(err => {
          this.isEditLoading = false;
          this.$toast.error(err?.data?.msg || err?.msg || '请求异常');
        });
    },

    /**
     * @desc 请求 删除
     */
    requestDelete() {
      this.isDeleteLoading = true;
      this.$myApi.categoryMenu
        .delete(this.currentRow.id)
        .then(() => {
          this.isDeleteLoading = false;
          this.$toast.success('删除成功！');
          this.handleHideDeleteModal();
          this.requestTableList();
        })
        .catch(err => {
          this.isDeleteLoading = false;
          this.$toast.error(err?.data?.msg || err?.msg || '请求异常');
        });
    },

    /**
     * @desc 处理 menu 展示
     */
    normalizer(node) {
      return {
        id: node.id,
        label: node.categoryName,
        children: node.categories,
      };
    },

    /**
     * @desc 清空表单值
     */
    handleClearFormData() {
      // TODO: 清空事件待优化；不然初始化的值需要写两遍；data 中一次，和这里一次；
      this.formData = {};
    },

    /**
     * @desc 回显表单值
     */
    handleRecoveryFormData(row: any) {
      this.formData = {
        brand_id: row.brandId,
        sort: row.sort,
        category_id: row.categoryId,
        category_name: row.categoryName,
        banner_path: row.bannerPath,
        type: row.type,
        parent_id: row.parentId || undefined,
        products1: row.products.join(','),
      };
    },

    /**
     * @desc 新增
     */
    handleAddEvent() {
      this.editMode = 'add';
      this.handleShowFormModal();
    },

    /**
     * @desc 表格点击事件 编辑
     */
    handleRowEdit(row: any) {
      this.editMode = 'edit';
      this.currentRow = row;
      this.handleRecoveryFormData(row);
      this.handleShowFormModal();
    },

    /**
     * @desc 表格点击事件 删除
     */
    handleRowDel(row: any) {
      this.currentRow = row;
      this.handleShowDeleteModal();
    },

    /**
     * @desc 检查表单数据是否合格
     */
    handleCheckFormData() {
      // const { name, page_url, control_url, parent_id, sort, icon, is_show, is_enabled } = this.formData;
      const { resourceType, name, page_url, control_url } = this.formData;
      // const isAddMode = this.editMode === 'add';
      if (!name) {
        this.$toast.error('请填写名称！');
        return false;
      }

      if (resourceType == ResourceTypeEnum.menu) {
        if (!page_url) {
          this.$toast.error('请添加菜单地址！');
          return false;
        }
      }

      if (resourceType == ResourceTypeEnum.api) {
        if (!control_url) {
          this.$toast.error('请添加接口地址！');
          return false;
        }
      }

      return true;
    },

    /**
     * @desc 提交表单
     */
    handleSubmit() {
      // if (!this.handleCheckFormData()) {
      //   return;
      // }
      if (this.editMode === 'edit') {
        this.requestEdit();
      } else {
        this.requestAdd();
      }
    },

    /**
     * @desc 显示标签表单弹框
     */
    handleShowFormModal() {
      this.isShowFormModal = true;
    },

    /**
     * @desc 隐藏标签表单弹框
     */
    handleHideFormModal() {
      this.isShowFormModal = false;
      this.handleClearFormData();
    },

    /**
     * @desc 显示删除标签弹框
     */
    handleShowDeleteModal() {
      this.isShowDeleteModal = true;
    },

    /**
     * @desc 隐藏删除标签弹框
     */
    handleHideDeleteModal() {
      this.isShowDeleteModal = false;
    },
  },
});
