import { render, staticRenderFns } from "./z-switch.vue?vue&type=template&id=237d4323&scoped=true"
import script from "./z-switch.vue?vue&type=script&lang=ts"
export * from "./z-switch.vue?vue&type=script&lang=ts"
import style0 from "./z-switch.less?vue&type=style&index=0&id=237d4323&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "237d4323",
  null
  
)

export default component.exports