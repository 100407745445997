import Vue from 'vue';
import Vuex from 'vuex';
import ZPanel from '@/components/base/panel/';
import ZTable from '@/components/base/table/';
import ZSwitch from '@/components/base/z-switch/';
import ZSelect from '@/components/base/z-select/';
import Btn from '@/components/base/btn/';
import Pagenation from '@/components/base/pagenation/';
import Modal from '@/components/base/modal/';
import FormItem from '@/components/base/form-item/';
import { setTreeData } from '@/utils';

const { mapGetters, mapActions } = Vuex;

import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import { ResourceTypeEnum, ResourceTypeDict, MethodTypeEnum, MethodTypeDict } from './constans';
import { objToArray } from '@/utils';

export default Vue.extend({
  name: 'AdminMenu',
  components: {
    ZPanel,
    ZTable,
    ZSwitch,
    ZSelect,
    Pagenation,
    Modal,
    FormItem,
    Treeselect,
    Btn,
  },
  data() {
    return {
      page: 1,
      limit: 10,
      pageTotal: 0,
      tableData: [],
      isLoading: false,
      isAddLoading: false,
      isEditLoading: false,
      isDeleteLoading: false,
      currentRow: {},
      isShowDeleteModal: false,
      isShowFormModal: false,
      editMode: 'add',
      formData: {
        resourceType: ResourceTypeEnum.menu,
        method: MethodTypeEnum.get,
        is_show: 1,
        is_enabled: 1,
      },
    };
  },
  computed: {
    ...mapGetters('common', {
      userInfo: 'getUserInfo',
    }),
    menuTree() {
      return setTreeData(this.tableData);
    },
    ResourceTypeEnum() {
      return ResourceTypeEnum;
    },
    RsourceTypeArr() {
      return objToArray(ResourceTypeDict, { initVal: false });
    },
    MethodTypeArr() {
      return objToArray(MethodTypeDict, { valueType: 'string' });
    },
  },
  mounted() {
    this.requestTableList();
  },
  methods: {
    ...mapActions({
      toggleSignInModal: 'common/toggleSignInModal',
    }),

    /**
     * @desc 刷新
     */
    refresh() {
      this.requestTableList();
    },

    /**
     * @desc 请求 获取资源列表
     */
    requestTableList() {
      this.isLoading = true;

      this.$myApi.menu
        .getAllMenuTree()
        .then((res: any) => {
          this.tableData = res?.data || [];
          console.log(res.data);

          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    /**
     * @desc 请求 新增
     */
    requestAdd() {
      const params = {
        ...this.formData,
        parent_id: this.formData.parent_id || 0,
      };
      if (this.formData.resourceType == ResourceTypeEnum.menu) {
        delete params.control_url;
        delete params.method;
      }
      if (this.formData.resourceType == ResourceTypeEnum.api) {
        delete params.page_url;
      }
      this.isAddLoading = true;
      this.$myApi.menu
        .create(params)
        .then(() => {
          this.isAddLoading = false;
          this.$toast.success('添加成功！');
          this.handleHideFormModal();
          this.requestTableList();
        })
        .catch(err => {
          this.isAddLoading = false;
          this.$toast.error(err?.data?.msg || err?.msg || '添加失败');
        });
    },

    /**
     * @desc 请求 修改
     */
    requestEdit() {
      const params = {
        ...this.formData,
        id: this.currentRow.id,
      };
      this.isEditLoading = true;
      this.$myApi.menu
        .update(params)
        .then(() => {
          this.isEditLoading = false;
          this.$toast.success('修改成功！');
          this.handleHideFormModal();
          this.requestTableList();
        })
        .catch(err => {
          this.isEditLoading = false;
          this.$toast.error(err?.data?.msg || err?.msg || '请求异常');
        });
    },

    /**
     * @desc 请求 删除
     */
    requestDelete() {
      this.isDeleteLoading = true;
      this.$myApi.menu
        .delete(this.currentRow.id)
        .then(() => {
          this.isDeleteLoading = false;
          this.$toast.success('删除成功！');
          this.handleHideDeleteModal();
          this.requestTableList();
        })
        .catch(err => {
          this.isDeleteLoading = false;
          this.$toast.error(err?.data?.msg || err?.msg || '请求异常');
        });
    },

    /**
     * @desc 处理 menu 展示
     */
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        children: node.children,
      };
    },

    /**
     * @desc 清空表单值
     */
    handleClearFormData() {
      // TODO: 清空事件待优化；不然初始化的值需要写两遍；data 中一次，和这里一次；
      this.formData = {
        resourceType: ResourceTypeEnum.menu,
        method: MethodTypeEnum.get,
        is_show: 1,
        is_enabled: 1,
      };
    },

    /**
     * @desc 回显表单值
     */
    handleRecoveryFormData(row: any) {
      this.formData = {
        method: row.method,
        name: row.name,
        page_url: row.page_url,
        control_url: row.control_url,
        parent_id: row.parent_id || undefined,
        sort: row.sort,
        icon: row.icon,
        is_show: row.is_show,
        is_enabled: row.is_enabled,
        resourceType: row.page_url ? ResourceTypeEnum.menu : ResourceTypeEnum.api,
      };
    },

    /**
     * @desc 新增
     */
    handleAddEvent() {
      this.editMode = 'add';
      this.handleShowFormModal();
    },

    /**
     * @desc 表格点击事件 编辑
     */
    handleRowEdit(row: any) {
      this.editMode = 'edit';
      this.currentRow = row;
      this.handleRecoveryFormData(row);
      this.handleShowFormModal();
    },

    /**
     * @desc 表格点击事件 删除
     */
    handleRowDel(row: any) {
      this.currentRow = row;
      this.handleShowDeleteModal();
    },

    /**
     * @desc 检查表单数据是否合格
     */
    handleCheckFormData() {
      // const { name, page_url, control_url, parent_id, sort, icon, is_show, is_enabled } = this.formData;
      const { resourceType, name, page_url, control_url } = this.formData;
      // const isAddMode = this.editMode === 'add';
      if (!name) {
        this.$toast.error('请填写名称！');
        return false;
      }

      if (resourceType == ResourceTypeEnum.menu) {
        if (!page_url) {
          this.$toast.error('请添加菜单地址！');
          return false;
        }
      }

      if (resourceType == ResourceTypeEnum.api) {
        if (!control_url) {
          this.$toast.error('请添加接口地址！');
          return false;
        }
      }

      return true;
    },

    /**
     * @desc 提交表单
     */
    handleSubmit() {
      if (!this.handleCheckFormData()) {
        return;
      }
      if (this.editMode === 'edit') {
        this.requestEdit();
      } else {
        this.requestAdd();
      }
    },

    /**
     * @desc 显示标签表单弹框
     */
    handleShowFormModal() {
      this.isShowFormModal = true;
    },

    /**
     * @desc 隐藏标签表单弹框
     */
    handleHideFormModal() {
      this.isShowFormModal = false;
      this.handleClearFormData();
    },

    /**
     * @desc 显示删除标签弹框
     */
    handleShowDeleteModal() {
      this.isShowDeleteModal = true;
    },

    /**
     * @desc 隐藏删除标签弹框
     */
    handleHideDeleteModal() {
      this.isShowDeleteModal = false;
    },
  },
});
