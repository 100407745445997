export enum ResourceTypeEnum {
  menu = 1,
  api = 2,
}

export const ResourceTypeDict = {
  [ResourceTypeEnum.menu]: '菜单',
  [ResourceTypeEnum.api]: '接口',
};

export enum MethodTypeEnum {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
  del = 'DELETE',
}

export const MethodTypeDict = {
  [MethodTypeEnum.get]: 'get',
  [MethodTypeEnum.post]: 'post',
  [MethodTypeEnum.put]: 'put',
  [MethodTypeEnum.del]: 'del',
};
